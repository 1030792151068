

























@import "style";
@import "~common/date-picker-styles";


.rx-page-report-report-picker-daily-1 {
    @include calendar-grid;
    @include calendar;
    @include calendar-wrapper;
}

.rx-page-report-report-picker-daily-2 {
    @include calendar-cell;
}