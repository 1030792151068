

















@import 'style.scss';


.rx-page-analyze-metric-chart-1 {
    min-height: 212rem;
}

.rx-page-analyze-metric-chart-2 {
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: flex-end;
    margin-top: 8rem;
    svg {
        color: $yellow-50;
        margin-right: 8rem;
        opacity: 0.4;
    }
}

.rx-page-analyze-metric-chart-3 {
    @include tooltip;
    padding-bottom: 2rem;
}

.rx-page-analyze-metric-chart-4 {
    display:flex;
    justify-content: space-between;
    align-items: flex-start;
}