


































@import 'style';


.rx-page-analyze-index-1 {
    @include container;
    padding: 24rem;
    display: flex;
    flex-flow: row wrap;
    gap: 37rem 8rem;
}