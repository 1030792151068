



@import 'style';




.rx-common-tabs-1 {
    display: flex;
    flex-flow: row nowrap;
    border-bottom: 1rem solid $gray-50;
    justify-self: stretch;
}




























.rx-common-tabs-2 {
    padding: 4rem 32rem 2rem 32rem;
    min-width: 158rem;
    position: relative;
    @include text-body-link;
    color: $gray-70;
    text-align: center;
    border-radius: 6rem 6rem 0 0;
    &.active {
        color: $blue-50;
        &:after {
            content: "";
            position: absolute;
            bottom: -2rem;
            left: 0;
            width: 100%;
            height: 3rem;
            border-radius: 3rem;
            background-color: $blue-50;
        }
    }
    &:hover {
        background-color: rgba($white, 0.5);
    }

}