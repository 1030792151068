













    @import 'style';


.rx-page-regional-dashboard-region-sidebar-1 {
    @include container;
    padding: 24rem;
}

.rx-page-regional-dashboard-region-sidebar-2 {
    @include text-label;
    color: $gray-60;
    margin: 16rem 0;
}