









    @import 'style';


.rx-page-policy-forms-common-1 {
    display: flex;
    flex-direction: column;
    margin-bottom: 16rem;
}

.rx-page-policy-forms-common-2 {
    @include text-label;
    color: $gray-70;
    margin-bottom: 3rem;
}

.rx-page-policy-forms-common-3 {
    display: flex;
    gap: 16rem;
}



































.rx-page-policy-forms-common-4 {
    @include text-body-tiny;
    /* prevent the container from resizing as we add or remove help text */
    height: 19.2rem;
    color: $gray-70;

    &.error {
        color: $red-70;
    }
}