












@import '../style.scss';





























.rx-page-analyze-chart-util-1 {
    background-color: rgba(35, 42, 53, 0.9); //grey-90
    color: $gray-30;
    border-radius:4rem;
    z-index: 99;
    font-size: 16rem;
    width: fit-content;
    &.label > div {
        border-top: 1rem solid $gray-70;
    }
}

.rx-page-analyze-chart-util-2 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 4rem 0rem;
    white-space: nowrap;
}

.rx-page-analyze-chart-util-3 {
    padding: 0rem 8rem 0rem 16rem;
}














































































.rx-page-analyze-chart-util-4 {
    width: 100%;
    height: 300px;
    overflow: hidden;
    position: relative;
}
.rx-page-analyze-chart-util-5 {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}