










@import 'style.scss';


.rx-common-hour-range-slider-1 {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 4rem;
    max-width: 248rem;
}

.rx-common-hour-range-slider-2 {
    display: grid;
    grid-template-columns: repeat(25, 1fr);
    align-items: center;
    * {
        grid-row: 1;
    }
}

.rx-common-hour-range-slider-3 {
    grid-column: 1 / -1;
    height: 8rem;
    background-color: $gray-30;
    border-radius: 6rem;
}

.rx-common-hour-range-slider-4 {
    height: 8rem;
    background-color: $blue-10;
    margin: 0 -5rem 0 5rem;
}

.rx-common-hour-range-slider-5 {
    justify-self: start;
    width: 10rem;
    height: 16rem;
    border-radius: 3rem;
    background-color: $blue-50;
    .end {
        justify-self: end;
    }
    &:hover {
        box-shadow: 0rem 0rem 4rem $blue-50;
    }
}

.rx-common-hour-range-slider-6 {
    @include text-caption;
    color: $gray-70;
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: auto;
    justify-items: center;
    justify-content: space-between;
}

.rx-common-hour-range-slider-7 {
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    align-items: center;
    &.remove_button {
        grid-template-columns: 1fr auto 1fr auto;
    }
    max-width: 248rem;
}

.rx-common-hour-range-slider-8 {
    @include button;
    margin-left: 8rem;
    width: 36rem;
    height: 36rem;
    position: relative;
    display: flex;
    align-items: center;
    svg {
        font-size: 28rem;
        &:last-child {
            margin: 2rem -6rem;
        }
    }
}

.rx-common-hour-range-slider-9 {
    width: 48rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $gray-70;
}