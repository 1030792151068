










@import 'style';


.rx-root-page-review-terms-1 {
    width: 480rem;
    margin: auto;
    margin-top: 120rem;
}

.rx-root-page-review-terms-2 {
    @include text-body;
    color: $gray-70;
    margin-top: 24rem;
}

.rx-root-page-review-terms-3 {
    margin-top: 32rem;
    margin-bottom: 32rem;
}

.rx-root-page-review-terms-4 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 8rem;
}

.rx-root-page-review-terms-5 {
    @extend %link;
    padding-left: 6rem;
    color: $blue-50;
}

.rx-root-page-review-terms-6 {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    height: 100%;
}