









































@import 'style';


.rx-root-page-region-1 {
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: stretch;
    grid-template-rows: 100%;
    flex: 1 1 auto;
    overflow: hidden;
    @media print {
        display: block;
    }
}

.rx-root-page-region-2 {
    display: grid;
    position: relative;
    align-items: stretch;
    overflow: hidden;
    @media print {
        display: block;
    }
}