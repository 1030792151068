









@import "style";
@import "~react-day-picker/lib/style.css";

@mixin daily-nav-border {
    @include link-border;
    height: 36rem;
    border-radius: 6rem;
    box-sizing: border-box;
    padding: 6rem;
}


.rx-page-report-report-nav-1 {
    display: flex;
    gap: 8rem;
    flex-flow: row nowrap;
    height: 36rem;
    align-items: stretch;
    width: 100%;

    > :first-child {
        flex: 1;
    }
}

.rx-page-report-report-nav-2 {
    display: grid;
    grid: 1fr / auto-flow auto;
    gap: 8rem;
}

.rx-page-report-report-nav-3 {
    @include daily-nav-border;
    width: 36rem;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    font-size: 24rem;
    color: $gray-70;
    transition-property: color, background-color, border-color;
    transition-duration: 200ms;
    user-select: none;

    &.disabled {
        cursor: default;
        background-color: $gray-30;
        color: $white;
    }
    &:not(.disabled) {
        @include shadow-2;
        &:hover {
            @include shadow-hover;
        }
    }
}

.rx-page-report-report-nav-4 {
    @include button;
    @include text-body;
    color: $white;
    padding: 6rem 12rem;
    background-color: $blue-50;
    user-select: none;
    &.active {
        cursor: default;
        background-color: $gray-30;
        color: $white;
        box-shadow: none;
        &:hover {
            box-shadow: none;
            transform: none;
        }
    }
    &:not(.active) {
        @include shadow-2;
        &:hover {
            background-color: $blue-70;
            border-color: $blue-30;
            color: $white;
            @include shadow-hover
        }
    }
}