









@import 'style.scss';


.rx-common-policy-rule-time-1 {
    @include text-caption;
    letter-spacing: 0.5rem;
    display: flex;
    align-items: center;
    column-gap: 4rem;

    color: $gray-70;
    &.hovered {
        color: $gray-90;
    }

    svg {
        display: block;
        margin-top: auto;
        margin-bottom: auto;
    }
    &.large {
        @include text-body-tiny;
        color: $gray-90;
        column-gap: 12rem
    }
}

.rx-common-policy-rule-time-2 {
    width: 12rem;
    height: 12rem;
    &.large {
        width: 20rem;
        height: 20rem;
    }
}