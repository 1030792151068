


























@import 'style.scss';


.rx-page-explore-page-heatmaps-area-origin-destination-reports-metadataPanel-1 {
    display: flex;
    flex-direction: column;
    gap: 16rem;
}