





























@import 'style.scss';

.rx-page-explore-page-heatmaps-hex-bins-popup-1 {
    @include text-label;
    padding: 8rem;
    pointer-events: none;
    color: $gray-70;
    svg {
        fill: $blue-50;
        font-size: 28rem;
        width: 1em;
        height: 1em;
        margin: -9rem -3rem;
    }
}
.rx-page-explore-page-heatmaps-hex-bins-popup-2 {
    @include text-label-bold;
    color: $gray-90;
}