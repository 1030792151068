






@import 'style.scss';


.rx-common-logged-out-header-1 {
    height: 54rem;
    flex: 0 0 auto;
    background-color: $gray-90;
    padding: 14rem 16rem;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
}

.rx-common-logged-out-header-2 {
    height: 100%;
    width: auto;
}