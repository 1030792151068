































@import '../style.scss';


.rx-page-analyze-multi-line-graph-1 {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.rx-page-analyze-multi-line-graph-2 {
    display:flex;
    align-items: center;
    padding: 0rem 12rem;
    p {
        padding: 4rem 8rem;
        font-size: 12rem;
    }
}