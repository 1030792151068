

















@import "~common/date-picker-styles";
@import "style";


.rx-page-report-report-picker-monthly-1 {
    display: grid;
    grid-template-columns: auto auto auto;
    width: 294rem;
    height: 252rem;
    grid-row-gap: 15rem;
    grid-column-gap: 23.33rem;
    padding: 7.5rem 11.67rem;
    position: relative;
    @include calendar;
}

.rx-page-report-report-picker-monthly-2 {
    display: flex;
    align-items: center;
    justify-content: center;
    color: $gray-90;
    border-radius: 6rem;
    transition-property: color, background-color;
    transition-duration: 100ms;
    @include text-label-bold;
    &.preliminary {
        position: relative;
        &:after {
            right: 15rem;
            top: 10rem;
            z-index: 1;
            @include preliminary-dot;
        }
        &:hover, &.active {
            &:after {
                right: -4rem;
                top: -4rem;
            }
        }
    }
}