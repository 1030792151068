



























@import 'style';


.rx-page-analyze-analyze-1 {
    @include container;
    padding: 24rem;
    display: flex;
    flex-flow: row wrap;
    align-items: flex-end;
    gap: 35.2rem 8rem;
    > * {
        flex-shrink: 0;
    }
}

.rx-page-analyze-analyze-2 {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}