










@import 'style';


.rx-root-page-login-1 {
    @include headline-3;
}

.rx-root-page-login-2 {
    width: 100%;
    max-width: 520rem;
    margin: auto;
}

.rx-root-page-login-3 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 32rem;
}

.rx-root-page-login-4 {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    margin-bottom: 16rem;
    width: 100%;
    max-width: 520rem;
}

.rx-root-page-login-5 {
    @include text-label;
    color: $gray-70;
}

.rx-root-page-login-6 {
    color: $red-50;
}

.rx-root-page-login-7 {
    margin-top: 24rem;
    margin-bottom: 40rem;
}

.rx-root-page-login-8 {
    @extend %link;
    font-weight: 500;
    color: $blue-50;
}

.rx-root-page-login-9 {
    background: $red-00;
    border: 1rem solid $red-50;
    box-sizing: border-box;
    border-radius: 6rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 520rem;
    padding: 24rem;
    margin-top: 24rem;
    color: $red-50;

    > span {
        font-family: Work Sans;
        font-style: normal;
        font-weight: 500;
        font-size: 16rem;
        line-height: 150%;
        margin-right: 12rem;
    }
}