






















































@import 'style.scss';


.rx-page-policy-page-details-1 {
    @include text-body-link-underlined;
    color: $blue-50;
}

.rx-page-policy-page-details-2 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}