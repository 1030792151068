







@import 'style';


.rx-page-explore-common-total-count-1 {
    @include headline-4;
    color: $white;
    line-height: 26rem;
}

.rx-page-explore-common-total-count-2 {
    border-radius: 0 0 6rem 6rem;
    margin: 0 -16rem -16rem -16rem;
    display: grid;
    grid-template-columns: 1fr auto;
    padding: 16rem;
    background-color: $gray-90;
    @include text-body;
    color: $gray-60;
    overflow: hidden;
    height: 56rem;
    &.mobile {
        border-radius: 6rem 6rem 0 0;
        background-color: $gray-90;
        margin: 0;
    }
    &.stand-alone {
        border-radius: 6rem;
    }
}