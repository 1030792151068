




















@import 'style.scss';


.rx-page-regional-dashboard-region-jurisdiction-table-1 {
    background-color: $gray-30;
    width: 80rem;
    height: 19rem;
    border-radius: 3rem;
    position: relative;
    overflow: hidden;

    &::before {
        content: '';
        display: block;
        position: absolute;
        left: -82px;
        height: 100%;
        width: 82px;
        background: linear-gradient(to right, transparent 0%, #C5CDD4 50%, transparent 100%);
        animation: load 1s cubic-bezier(0.4, 0.0, 0.2, 1) infinite;
    }

    @keyframes load {
        from {
            left: -82px;
        }
        to   {
            left: 100%;
        }
    }
}