/* Common styles to be used for any date pickers */

@import 'style';

@mixin calendar-cell {
    width: 32rem;
    height: 32rem;
    @include text-label-bold;
    color: $gray-90;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}

@mixin preliminary-dot {
    content: '';
    position: absolute;
    width: 12rem;
    height: 12rem;
    border-radius: 50%;
    background-color: $yellow-50;
    border: 2rem solid $white;
}

@mixin calendar {
    .outside {
        color: $gray-60;
        @content;
    }
    .missing,
    .missing * {
        color: $gray-50;
        font-size: 20rem;
        .outside,
        &.outside {
            color: $gray-30;
            * {
                color: $gray-30;
            }
        }
    }
    .disabled,
    .disabled *,
    :disabled,
    :disabled * {
        color: $gray-30;
        cursor: default;
    }
    .active,
    .active * {
        background-color: $blue-50;
        color: $white;
        cursor: default;
    }
    .preliminary {
        position: relative;
        &:after {
            right: -2rem;
            top: -2rem;
            @include preliminary-dot;
        }
    }

    a:not(.active):not(.disabled):hover,
    a:not(.active):not(.disabled):hover *,
    button:not(.active):not(:disabled):hover,
    button:not(.active):not(:disabled):hover * {
        background-color: $gray-30;
    }
}

@mixin calendar-wrapper {
    padding: 5rem;
    margin-top: 6rem;
    position: relative;
}

@mixin calendar-grid {
    display: grid;
    grid-template-columns: repeat(7, 32rem);
    grid-row-gap: 10rem;
    grid-column-gap: 10rem;
}
