








    @import 'style';


.rx-root-confidential-1 {
    background-color: $white;
    width: 100%;
    color: $gray-70;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    column-gap: 8rem;
    padding: 8rem;
    @include text-label;
    border-top: 1px solid $gray-30;
}

.rx-root-confidential-2 {
    @include text-label-link;
    text-decoration: underline;
    @media print {
        display: none;
    }
}

.rx-root-confidential-3 {
    border-radius: 50%;
    background-color: $gray-30;
    height: 16rem;
    width: 16rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 24rem;

    :hover {
        background-color: $gray-50;
    }
}

.rx-root-confidential-4 {
    height: 12rem;
    width: 12rem;
}