





@import 'style';







.rx-common-search-input-1 {
    position: relative;
    margin: 0 12px;
}

.rx-common-search-input-2 {
    position: absolute;
    font-size: 20px;
    color: $gray-60;
    top: calc(40% - 10px);
    left: 8px;
}

.rx-common-search-input-3 {
    padding-left: 32px;
    margin-bottom: 10px;
    border: 1px solid $gray-30;
    box-sizing: border-box;
    border-radius: 6px;
    width: 100%;
    height: 36px;
}