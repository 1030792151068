


















@import 'style.scss';


.rx-root-page-app-root-router-1 {
    @include headline-3;
    flex: 1 0 0;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    padding: 24rem;
    & > button {
        margin-top: 24rem
    }
}