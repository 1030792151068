



@import 'style';


.rx-common-title-1 {
    color: $gray-90;
    @include subhead;
    display: flex;
    flex-flow: row wrap;
    align-items: flex-end;
    justify-content: space-between;
    justify-items: end;
    margin-right: -24rem;
}
.rx-common-title-2 {
    color: $gray-70;
    @include subhead;
}

.rx-common-title-3 {
    @include headline-3;
    margin-top: 4rem;
}

.rx-common-title-4 {
    align-self: start;
    padding-bottom: 18rem;
    padding-right: 24rem;
}

.rx-common-title-5 {
    position: relative;
    flex: 1 0 auto;
    display: flex;
    flex-flow: row;
    padding-right: 24rem;
    margin-right: -8rem;
    > * {
        margin-right: 8rem;
    }
    justify-content: flex-end;
}