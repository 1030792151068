














@import 'style.scss';

@mixin invoice-alert {
    span {
        @include text-body-tooltip
    }
}


.rx-page-fees-page-list-invoice-row-1 {
    font-weight: bold;
}

.rx-page-fees-page-list-invoice-row-2 {
    @include text-body-link-disabled;
}

.rx-page-fees-page-list-invoice-row-3 {
    @include invoice-alert;
    color: $blue-50;
}

.rx-page-fees-page-list-invoice-row-4 {
    @include invoice-alert;
    color: $red-50;
}