


















    @import 'style';


.rx-page-policy-page-list-1 {
    background-color: $blue-00;
}

.rx-page-policy-page-list-2 {
    @include table;
    width: 100%;

    tbody tr > :nth-child(2) {
        padding: 7.5rem 8rem;
    }

    tbody tr th {
        padding-top: 36rem;
        padding-bottom: 12rem;
    }

    .title {
        @include text-body-bold;
        font-size: 20rem;
        color: $gray-90;
        padding-top: 48rem;
        padding-bottom: 24rem;
    }

    .first {
        padding-top: 0rem;
    }
}

.rx-page-policy-page-list-3 {
    line-height: 29rem;
    > :not(:first-child) {
        margin-top: 6rem;
    }
}