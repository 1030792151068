




@import 'style';


.rx-page-explore-common-heatmap-key-1 {
    display: grid;
    grid-template-rows: 8rem auto;
	align-items: stretch;
}

.rx-page-explore-common-heatmap-key-2 {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 40rem;
}

.rx-page-explore-common-heatmap-key-3 {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 40rem;
    height: 18rem;
    justify-items: center;
    align-items: center;
    @include text-caption-bold;
}

.rx-page-explore-common-heatmap-key-4 {
    border: 1rem solid fade-out($gray-90, 0.9);
    border-width: 1rem 0;
    &:first-child {
        border-top-left-radius: 32rem;
        border-bottom-left-radius: 32rem;
        border-left-width: 1rem;
    }
    &:last-child {
        border-top-right-radius: 32rem;
        border-bottom-right-radius: 32rem;
        border-right-width: 1rem;
    }
}

.rx-page-explore-common-heatmap-key-5 {
    position: absolute;
    left: 16rem;
    bottom: 40rem;
    color: $gray-90;
    @include container;
    padding: 12rem 16rem 8rem 16rem;
    @include shadow-8;
	display: flex;
	flex-flow: column nowrap;
	align-items: flex-end;
    font-size: 10rem;
    line-height: normal;
    pointer-events: none;
}
.rx-page-explore-common-heatmap-key-6 {
    @include text-caption-bold;
}