























@import 'style';
$breakpoint-1: 800rem;

@mixin table-section {
    background-color: $white;
    border: 1rem solid $gray-30;
    box-sizing: border-box;
    border-radius: 6rem;
    position: relative;
    break-inside: avoid;
}


.rx-page-report-tables-1 {
    @include headline-3;
    margin: 4rem 0 42rem 0;
    grid-column: 1 / -1;
}

.rx-page-report-tables-2 {
    @include table-section;
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    flex: 1;
    @media print {
        display: block;
    }
    padding: 24rem;
}

.rx-page-report-tables-3 {
    max-height: 440rem;
    overflow: auto;
}

.rx-page-report-tables-4 {
    @include subhead;
    color: $gray-70;
    padding: 13rem 24rem 12rem 24rem;
    border-bottom: 1rem solid $gray-30;
    margin: -24rem -24rem 0 -24rem;
}















.rx-page-report-tables-5 {
    margin-bottom: -24rem;
    margin-left: -24rem;
    columns: 2;
    &.small {
        columns: 3;
    }
}

.rx-page-report-tables-6 {
    @include table-section;
    margin-bottom: 24rem;
    flex: 1 0 0;
    margin-left: 24rem;
    display: flex;
    position: relative;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: center;
    height: 150rem;
    padding: 24rem;
    &.small {
        height: 106rem;
        overflow: visible;
    }
    overflow: auto;
}

.rx-page-report-tables-7 {
    text-align: center;
    box-sizing: border-box;
    transition: opacity 150ms;
    opacity: 1;
    @include headline-3;
    &.hide {
        opacity: 0;
    }
    &.small {
        @include headline-5;
    }
}
.rx-page-report-tables-8 {
    @include text-body-link;
    color: $gray-70;
    &.small {
        @include text-label-link;
    }

    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
}
.rx-page-report-tables-9 {
    &.tooltip {
        @include tooltip;
    }
}

.rx-page-report-tables-10 {
    @include text-label;
    color: $gray-60;
    margin-top: -6rem;
    text-align: center;
}

.rx-page-report-tables-11 {
    @include text-label;
    color: $red-50;
    margin-top: -6rem;
    text-align: center;
}























































































.rx-page-report-tables-12 {
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-end;
}

.rx-page-report-tables-13 {
    @include headline-3;

}
.rx-page-report-tables-14 {
    @include text-body;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
}
.rx-page-report-tables-15 {
    @include text-body;
    margin-top: -12rem;
    margin-bottom: 4rem;
}


































.rx-page-report-tables-16 {
    @include text-body;
    color: $gray-70;
    padding: 11rem 24rem 9rem 24rem;
    margin-bottom: 16rem;
    border-bottom: 1rem solid $gray-30;
}

.rx-page-report-tables-17 {
    @include text-body-link;
    @include navigation-link;
    color: $gray-90;
    padding: 0 12rem;
    height: 40rem;
    display: flex;
    align-items: center;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin: 0 28rem;
    border-radius: 6rem;
    &:last-child {
        margin-bottom: 24rem;
    }
}
















































.rx-page-report-tables-18 {
    @include table;
    width: 100%;
    table-layout: fixed;
    svg {
        flex: 0 0 auto;
        font-size: 24rem;
        margin-right: 8rem;
        color: $gray-90;
    }
}

.rx-page-report-tables-19 {
    display: flex;
    margin-top: 16rem;
}



































.rx-page-report-tables-20 {
    margin-left: 4rem;
    font-size: 20rem;
}

.rx-page-report-tables-21 {
    @include tooltip;
}

.rx-page-report-tables-22 {
    width: auto;
}
.rx-page-report-tables-23 {
    width: 300rem;
}

.rx-page-report-tables-24 {
    cursor: initial;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
}

































































































.rx-page-report-tables-25 {
    @include text-caption;
    color: $gray-60;
    margin-top: -6rem;
}





















































































































.rx-page-report-tables-26 {
    display: grid;
    grid-gap: 24rem;
    grid-template-columns: 2fr 1fr;

    a {
        @include text-body-link-underlined;
        color: $blue-50;
    }
}