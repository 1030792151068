






















    @import 'style';


.rx-page-policy-page-new-1 {
    @include text-body-link-underlined;
    color: $blue-50;
}

.rx-page-policy-page-new-2 {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(400rem, 1fr));
    grid-auto-rows: auto;
    grid-gap: 16rem;
}