




@import 'style';


.rx-common-table-search-input-1 {
    position: relative;
    margin-top: 24rem;
    @media print {
        display: none;
    }
    align-self: stretch;
}
.rx-common-table-search-input-2 {
    position: absolute;
    left: 0;
    top: calc(50% - 12rem);
    font-size: 24rem;
}
.rx-common-table-search-input-3 {
    @include forms-text-field;
    width: 100%;
    padding-left: 48rem;
    margin: 0 -12rem;
    &:not(:focus):not(:hover) {
        border-color: transparent;
    }
}