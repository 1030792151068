









@import '../style.scss';








































.rx-common-common-chart-1 {
    padding: 4rem 8rem;
}


















.rx-common-common-chart-2 {
    height: 12rem;
    width: 12rem;
    border-radius: 50%;
    margin: 0rem 8rem;
}

.rx-common-common-chart-3 {
    background-color: rgba(35, 42, 53, 0.9); //grey-90
    color: $gray-30;
    border-radius:4rem;
    z-index: 99;
    font-size: 16rem;
    width: fit-content;
    &.label > div {
        border-top: 1rem solid $gray-70;
    }
}

.rx-common-common-chart-4 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 4rem 0rem;
    white-space: nowrap;
}

.rx-common-common-chart-5 {
    padding: 0rem 8rem 0rem 16rem;
}