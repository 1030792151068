







@import 'style.scss';


.rx-common-code-block-1 {
    background-color: #212a35;
    border-radius: 6rem;
    padding: 16rem;
    position: relative;
    height: auto;
    color: $gray-60;
    > pre > code > ol {
        font-family: PT-Mono;
        box-sizing: border-box;
        font-size: 16rem;
        overflow-x: auto;
        list-style-position: inside;
        counter-reset: item;
        list-style-type: none;
    }
    @media screen {
        &:not(.expanded) {
            &:after {
                content: "";
                position: absolute;
                z-index: 2;
                bottom: 0rem;
                left: 0rem;
                width: 100%;
                height: 200rem;
                background: linear-gradient(180deg, rgba(34, 42, 53, 0) 0%, #222A35 100%);
                border-radius: 6rem;
                pointer-events: none;
            }
            > pre > code > ol {
                height: 200rem;
                overflow: hidden;
            }
        }
    }
    @media print {
        border: 1rem solid $gray-30;
        background-color: transparent;
        color: $gray-90;
    }
}

.rx-common-code-block-2 {
    z-index: 3;
    position: absolute;
    right: 24rem;
    height: auto;
    max-width: 70rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $white;
}

.rx-common-code-block-3 {
    font-size: 24rem;
    margin-right: 12rem;
}

.rx-common-code-block-4 {
    font-size: 24rem;
}

.rx-common-code-block-5 {
    max-width: 670rem;

    &:before {
        display: inline-block;
        width: 40rem;
        content: counter(item);
        counter-increment: item;
        vertical-align: baseline;
        line-height: 24rem;
    }
}