








    @import 'style';






















.rx-page-policy-forms-day-of-week-selector-1 {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-start;

    button:first-child {
        border-top-left-radius: 6rem;
        border-bottom-left-radius: 6rem;
    }

    button:last-child {
        border-top-right-radius: 6rem;
        border-bottom-right-radius: 6rem;
        border-right: 1rem solid $gray-30;
    }
}

.rx-page-policy-forms-day-of-week-selector-2 {
    font-size: 12rem;
    line-height: 150%;
    background-color: $white;
    color: $gray-90;
    text-decoration: none;
    cursor: pointer;
    border-radius: 0rem;
    padding: 6rem 12rem;
    border: 1rem solid $gray-30;
    border-right: none;

    &:hover {
        background-color: $gray-30;
    }

    &.selected {
        background-color: $blue-50;
        color: $white;

        &:hover {
            background-color: $blue-70;
        }
    }
}