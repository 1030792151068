







@import 'style.scss';


.rx-common-map-options-1 {
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    opacity: 1;
    transition: 1000ms all;
}

.rx-common-map-options-2 {
    @include text-label-bold;
    color: $gray-60;
    padding-bottom: 8rem;
    &:not(:first-child) {
        margin-top: 16rem;
    }
}

.rx-common-map-options-3 {
    $switch: ' + label';

    @include toggle-defaults($switch);

    &#{$switch} {
        @include text-body-link;
        color: $gray-90;
        padding: 8rem;
        svg {
            font-size: 20rem;
            margin-right: 8rem;
        }
    }
    &:not(:checked)#{$switch} svg {
        color: $gray-60;
    }
    &:not(:checked):not(:focus):not(:hover)#{$switch} {
        border-color: transparent;
    }
    &:checked#{$switch} {
        color: $white;
    }
}

.rx-common-map-options-4 {
    @include container;
    position: absolute;
    padding: 12rem;
    right: calc(100% + 8rem);
    top: 0;
    @include shadow-8;
    display: flex;
    flex-flow: column nowrap;
    transition: 100ms all;
    &.enter {
        transition-timing-function: ease-out;
    }
    &.exit {
        transition-duration: 200ms;
        transition-timing-function: ease-in;
    }
    &.enter, &.exit.exit-active {
        opacity: 0;
        transform: translateX(24rem);
    }
    &.enter.enter-active, &.exit {
        opacity: 1;
        transform: translateX(0rem);
    }
}