






@import 'style.scss';


.rx-root-loading-site-1 {
    position: relative;
    flex: 1 0 auto;
}