























@import 'style';


.rx-page-mode-shift-chart-1 {
    @include text-body;
    color: $gray-70;
    padding: 10rem 10rem;
    text-align: left;
}

.rx-page-mode-shift-chart-2 {
    @include text-body;
    color: $gray-70;
    padding-left: 30rem;
    padding-bottom: 40rem;
    text-align: left;
}

.rx-page-mode-shift-chart-3 {
    @include headline-6;
    border-radius: 4rem;
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: $gray-70;
}

.rx-page-mode-shift-chart-4 {
    @include text-body;
    font-size: 20px;
    font-weight: bold;
    color: $gray-70;
    min-height: 212rem;
    margin: auto;
    padding-top: 85rem;
}