








    @import 'style';


.rx-page-policy-dashboard-sidebar-1 {
    @include container;
    padding: 24rem;
    position: sticky;
    top: 0;
}

.rx-page-policy-dashboard-sidebar-2 {
    @include text-label;
    color: $gray-60;
}

.rx-page-policy-dashboard-sidebar-3 {
    margin-top: 16rem;
    display: flex;
    flex-direction: column;
    row-gap: 16rem;
}