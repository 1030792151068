



















@import 'style.scss';


.rx-page-policy-dashboard-row-1 {
    @include text-label;
    color: $gray-70;

    &.showingDetails {
        box-shadow: 0rem 8rem 4rem -4rem rgba(0, 0, 0, 0.05);
    }
}

.rx-page-policy-dashboard-row-2 {
    &:hover {
        cursor: pointer;
    }
}

.rx-page-policy-dashboard-row-3 {
    &.hovered {
        color: $gray-90;
    }
}

.rx-page-policy-dashboard-row-4 {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.rx-page-policy-dashboard-row-5 {
    height: 32rem;
    width: 32rem;
    &:hover {
        border-radius: 50%;
        background-color: $gray-10;
    }

    &.showingDetails {
        height: 72rem;
        border-radius: 8rem 0 0 0;
        background-color: $gray-10;
    }

    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 4rem;
}

.rx-page-policy-dashboard-row-6 {
    display: flex;
    justify-content: flex-end;

    &.hovered {
        color: $gray-90;
    }
}

.rx-page-policy-dashboard-row-7 {
    height: 32rem;
    width: 32rem;
    padding-right: 8rem;
    display: block;
    margin-top: auto;
    margin-bottom: auto;

    &.hovered {
        fill: $gray-90;
    }
}

.rx-page-policy-dashboard-row-8 {
    &.hovered {
        color: $gray-90;
    }
}

.rx-page-policy-dashboard-row-9 {
    height: 72rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 200rem;
    &.blue {
        color: $blue-50;
    }
}