






@import 'style';


.rx-common-select-number-field-1 {
    height: 60rem;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-end;
    flex: 0 1 104rem;
}

.rx-common-select-number-field-2 {
    @include text-label;
    color: $gray-70;
    margin-bottom: 3rem;
    &.disabled {
        display: none;
    }
}

.rx-common-select-number-field-3 {
    border: 1rem solid $gray-30;
    border-radius: 6rem;
    height: 36rem;
    &:hover {
        border-color: $gray-60;
    }
    &:focus-within {
        border-color: $blue-50;
    }
    > input {
        font-size: inherit;

        padding: 12rem 0 12rem 12rem;
        border: none;
        &::-webkit-inner-spin-button, &::-webkit-outer-spin-button {
            -webkit-appearance: none;
        }
        appearance: none;
        -moz-appearance: textfield;
        width: 100%;
        position: relative;
        z-index: 1;
        &:disabled {
            display: none;
        }
        background: none;
        min-width: 32rem;
    }
    .value {
        white-space: nowrap;
    }
    display: flex;
    position: relative;
    flex-flow: row nowrap;
    > select {
        opacity: 0;
        position: absolute;
        left: 0;
        top: 0;
        height:100%;
        width: 100%;
        text-align: right;
        right: 0;
        background-color: transparent;
    }
    > .value {
        display: flex;
        height: 100%;
        justify-content: space-between;
        align-items: center;
        svg {
            color: $gray-60;
            font-size: 24rem;
        }
    }
    > select:hover + .value svg {
        color: $gray-90;
    }
    > select:focus + .value svg {
        color: $blue-50;
    }
    > input:disabled ~ .value {
        width: 100%;
        margin-left: 12rem;
        color: $gray-60;
    }
}