





























@import 'style.scss';


.rx-page-health-health-incident-log-1 {
    @include container;
    margin-bottom: 16rem;
}
.rx-page-health-health-incident-log-2 {
    margin: -1rem;
    padding: 10rem 24rem 10rem 16rem;
    border-radius: 6rem 6rem 0 0;
    color: $white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $gray-70;
    & > h3 > a {
        margin-right: 8rem;
    }
    &.timeline::before {
        content:'';
        display:block;
        width:0;
        height:0;
        position:absolute;
        border-top: 12rem solid transparent;
        border-bottom: 12rem solid transparent;
        border-right:12rem solid $gray-70;
        left:0rem;
        top:24rem;
    }
    &.investigating {
        background-color: $red-50;
        border: 1rem solid $red-50;
        border-right:12rem solid $red-50;
        &.timeline::before {
            border-right:12rem solid $red-50;
        }
    }
    &.resolved {
        background-color: $gray-50;
        border: 1rem solid $gray-50;
        border-right:12rem solid $gray-50;
        color: $gray-70;
        &.timeline::before {
            border-right:12rem solid $gray-50;
        }
    }
}

.rx-page-health-health-incident-log-3 {
    padding: 16rem 24rem;
}

.rx-page-health-health-incident-log-4 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 24rem;
}

.rx-page-health-health-incident-log-5 {
    display: flex;
    align-items: center;
    > span {
        padding-right: 8rem;
    }
}

.rx-page-health-health-incident-log-6 {
    @include text-body-bold;
}

.rx-page-health-health-incident-log-7 {
    padding-top: 16rem;
}

.rx-page-health-health-incident-log-8 {
    display: inline-block;
    /* Account for the dropdown icon */
    width: calc(100% - 18rem);
}

.rx-page-health-health-incident-log-9 {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.rx-page-health-health-incident-log-10 {
    border: none;
    border-top-left-radius: 0rem;
    border-top-right-radius: 0rem;
    border-top: 1rem solid $gray-30;
    & > summary {
        border-bottom: none;
        margin: 0rem;
        padding: 10rem 0rem;
    }
}






































































































































.rx-page-health-health-incident-log-11 {
    list-style-type: none;
    &:last-child {
        margin-bottom: 0;
    }
    > li {
        position: relative;
        padding: 10rem;
    }
    > li:before {
        content: '';
        width: 8rem;
        height: 8rem;
        border-radius: 50%;
        background: $blue-50;
        position: absolute;
        left: -15rem;
        top: 32rem;
        z-index: 2;

    }
    > li.single-event:before {
        transform: translateY(-14rem);
    }
    > li.month {
        @include text-label;
        color: $gray-60;
        font-size: 1em;
    }
    > li.month:before {
        background: none;
    }
    > li:after {
        content: '';
        width: 1rem;
        height: calc(100% + 48rem);
        background: $gray-30;
        position: absolute;
        left: -12rem;
        top: 0;
        bottom: -100rem;
        margin-top: -24rem;
        margin-bottom: 24rem;
    }
}