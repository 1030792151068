











@import "style";


.rx-common-side-nav-1 {
    @include text-body-link;
    @include navigation-link;
    color: $gray-90;
    padding: 4rem 12rem;
    min-height: 40rem;
    display: flex;
    align-items: center;
    flex-flow: row nowrap;
    margin-left: 4rem;
    border-radius: 6rem;
    svg {
        flex: 0 0 auto;
        font-size: 24rem;
        margin-right: 8rem;
    }
    &:not(.active) svg {
        color: $gray-60;
    }
}




























































.rx-common-side-nav-2 {
    @include container;
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    flex: 1;
    position: sticky;
    top: -32rem;
    @media print {
        display: block;
    }
    padding: 24rem;
    @include not-mobile {
        max-height: calc(100vh - 124rem);
    }
}
.rx-common-side-nav-3 {
    overflow: auto;
    margin: -24rem;
    padding: 24rem;
}
.rx-common-side-nav-4 {
    margin: 24rem 0 20rem 0;
    color: $gray-60;
    @include text-label;
}
.rx-common-side-nav-5 {
    &:not(:last-child) {
    }
}