















@import 'style';


.rx-page-analyze-three-dimensional-chart-1 {
    display: grid;
    grid-template:  repeat(7, 1fr) 1fr / repeat(24, 1fr) 50rem;
    margin: 0 15rem 0 10rem;
}

.rx-page-analyze-three-dimensional-chart-2 {
    @include text-label;
    font-size: 9rem;
    width: 100%;
    height: 0;
    padding-bottom: calc(100% + 2rem);
    position: relative;
    color: transparent;
    outline: 2rem transparent;
    transition-property: border-color, transform, z-index;
    transition-duration: 150ms;
    border-radius: 3rem;
    flex: 0 0;
    overflow: hidden;
    box-sizing: border-box;
    background-color: white;
    text-align: center;
    &:before {
        content: '';
        display: block;
        top: -2rem;
        left: -2rem;
        position: absolute;
        width: calc(100% + 2rem);
        height: calc(100% + 2rem);
        background-color: var(--color);
        transition-property: opacity;
        transition-duration: 150ms;
        border-radius: 3rem;
    }
    transform: scale(90%);
    &:hover {
        transform: scale(130%);
        outline: 2rem solid var(--border);
        &:before {
            opacity: 0;
        }
        color: $gray-90;
        z-index: 1;
    }
}

.rx-page-analyze-three-dimensional-chart-3 {
    margin: 2rem;
}

.rx-page-analyze-three-dimensional-chart-4 {
    @include text-label;
}

.rx-page-analyze-three-dimensional-chart-5 {
    @include text-label;
    color: $gray-70;
    display: flex;
    text-align: center;
    align-items: flex-start;
    justify-content: center;
}

.rx-page-analyze-three-dimensional-chart-6 {
    @include text-label;
    color: $gray-70;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.rx-page-analyze-three-dimensional-chart-7 {
    margin-top: 40rem;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    @include subhead;
    min-height: 55rem;
    box-sizing: content-box;
    &.small {
        @include text-body;
        min-height: 44rem;
    }
    color: $gray-70;
}