
















@import 'style.scss';


.rx-page-policy-forms-form-base-1 {
    margin-bottom: 24rem;
}

.rx-page-policy-forms-form-base-2 {
    display: grid;
    grid-auto-columns: 2fr;
    grid-auto-flow: column;
    grid-gap: 16rem;
    & > div {
        display: flex;
        flex-direction: column;
    }
}