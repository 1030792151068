













@import 'style.scss';


.rx-page-policy-dashboard-table-1 {
    @include tooltip;
}





.rx-page-policy-dashboard-table-2 {
    @include table;
    width: 100%;

    border-collapse: separate;
    tbody {
        tr {
            height: 72rem;
        }
        tr:not(:last-child) td {
            border-bottom: 1rem solid $gray-30;
        }
        tr:not(:last-child).showingDetails td {
            border-bottom: none;
        }

        td {
            border: 1rem solid transparent;
        }
        tr.showingDetails.mainRowWithBorders td {
            border-top: 1rem solid $gray-30;
        }

        tr.showingDetails.mainRowWithBorders td:first-child {
            border-left: 1rem solid $gray-30;
            border-top-left-radius: 8rem;
        }
        tr.showingDetails.mainRowWithBorders td:last-child {
            border-right: 1rem solid $gray-30;
            border-top-right-radius: 8rem;
        }

        tr.subRowWithBorders td:first-child {
            border-left: 1rem solid $gray-30;
        }
        tr.subRowWithBorders td:last-child {
            border-right: 1rem solid $gray-30;
        }

        tr.lastOfDetails td {
            border-bottom: 1rem solid $gray-30;
        }
        tr.lastOfDetails td:first-child {
            border-bottom-left-radius: 8rem;
        }
        tr.lastOfDetails td:last-child {
            border-bottom-right-radius: 8rem;
        }
    }
}