







@import 'style.scss';


.rx-page-explore-page-heatmaps-hex-bins-privacy-callout-1 {
    justify-self: center;
    max-width: 752rem;
}