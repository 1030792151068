










@import 'style.scss';


.rx-page-explore-common-mode-picker-1 {
    display: grid;
    grid-gap: 16rem;
    align-content: start;
    justify-items: start;
}

.rx-page-explore-common-mode-picker-2 {
    display: grid;
}