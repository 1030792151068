



































@import 'style.scss';


.rx-page-explore-page-heatmaps-routes-index-1 {
    display: grid;
}