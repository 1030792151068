















    @import 'style';


.rx-page-segment-editor-segment-editor-panel-1 {
    margin-top: 8rem;
}