




























@import 'style';


.rx-page-analyze-analyze-filter-1 {
    width: 200rem;
}

.rx-page-analyze-analyze-filter-2 {
    width: 320rem;
}

.rx-page-analyze-analyze-filter-3 {
    width: 160rem;
}

.rx-page-analyze-analyze-filter-4 {
    margin-top: -19.2rem;
}

.rx-page-analyze-analyze-filter-5 {
    @include text-body-tiny;
    color: $gray-70;
}