





@import 'style';

@keyframes page_trip_filter_included_pct_load {
    from {
        left: -82rem;
    }
    to   {
        left: 100%;
    }
}


.rx-page-health-page-trip-filter-included-pct-1 {
    @include text-label-bold;
    white-space: nowrap;
}
.rx-page-health-page-trip-filter-included-pct-2 {
    @include text-label-bold;
    text-align: right;
    color: $gray-70;
    height: 19.2rem;
    white-space: nowrap;
}
.rx-page-health-page-trip-filter-included-pct-3 {
    color: $gray-90;
}
.rx-page-health-page-trip-filter-included-pct-4 {
    @include text-label-bold;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 144rem;
    color: $gray-70;
}
.rx-page-health-page-trip-filter-included-pct-5 {
    height: 4rem;
    background-color: $gray-50;
    border-radius: 2rem;
    position: relative;
    overflow: hidden;
    --value: 0%;
    &:before, &:after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        height: 100%;
    }
    &:before { // the bar for the current value
        transition: transform 600ms cubic-bezier(0.4, 0.0, 0.2, 1), background  350ms;
        left: -100%;
        background: $blue-50;
        width: 100%;
        transform: translate(var(--value));
    }
    &:after { // the animated loading bar
        content: '';
        left: -82rem;
        width: 82rem;
        background: transparent;
        transition: background 350ms;
    }
    &.loading {
        background-color: $gray-30;
        &:after {
            background: linear-gradient(to right, transparent 0%, $gray-50 50%, transparent 100%);
            @media (prefers-reduced-motion: no-preference) {
                animation: page_trip_filter_included_pct_load 1s cubic-bezier(0.4, 0.0, 0.2, 1) infinite;
            }
        }
        &:before {
            background: transparent;
        }
    }
}