





@import 'style.scss';










.rx-common-callout-1 {
    &.gray {
        @include container;
    }
    &.blue {
        @include container("blue");
    }
    &.yellow {
        @include container("yellow");
    }
    &.red {
        @include container("red");
    }
    &.green {
        @include container("green");
    }
    &.yellow {
        @include container("yellow");
    }
    @include text-body-link;
    &.shadow {
        @include shadow-8;
    }
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: start;
    padding: 16rem 16rem 16rem 24rem;
    grid-gap: 20rem;

    a {
        @include text-body-link-underlined;
        color: $blue-50;
    }

}

.rx-common-callout-2 {
    margin: -8rem;
    pointer-events: auto;
    cursor: default;
    background-color: transparent;
    border: none;
    color: $blue-50;
    &:not(:hover):not(:focus) {
        box-shadow: none;
    }
    &:not(:disabled):hover, &:not(:disabled):focus {
        color: white;
        background-color: $blue-30;
    }
    &.red {
        color: $red-50;
        &:not(:disabled):hover, &:not(:disabled):focus {
            color: white;
            background-color: $red-30;
        }
    }
    &.yellow {
        color: $gray-90;
        &:not(:disabled):hover, &:not(:disabled):focus {
            color: white;
            background-color: $yellow-00;
        }
    }
    &.green {
        color: $green-50;
        &:not(:disabled):hover, &:not(:disabled):focus {
            color: white;
            background-color: $green-30;
        }
    }
}