





















    @import 'style';


.rx-page-segment-editor-index-1 {
    overflow: hidden;
}

.rx-page-segment-editor-index-2 {
    display: grid;
    overflow: hidden;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    grid-template-rows: 1fr auto;
}