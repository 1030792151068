














    @import 'style';


.rx-page-policy-common-page-1 {
    @include button;
    display: flex;
    flex-direction: row;
    justify-self: start;
    align-items: center;
    svg:first-child {
        font-size: 24rem;
        margin-right: 8rem;
    }
}

.rx-page-policy-common-page-2 {
    display: flex;
    align-items: center;
    justify-content: space-between;

    > *:first-child {
        flex-grow: 1;
        margin-right: 16rem;
    }
}