












@import 'style';


.rx-page-analyze-metric-selector-1 {
    width: 200rem;
}