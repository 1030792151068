




































@import 'style';


.rx-page-custom-data-page-upload-1 {
    @include text-body-link-underlined;
    color: $blue-50;
}

.rx-page-custom-data-page-upload-2 {
    overflow-x: auto;
    max-width: 924rem;

    @include mobile {
        max-width: calc(100vw - 190rem);
    }
    @include mobile-tiny {
        max-width: 90vw;
    }
}

.rx-page-custom-data-page-upload-3 {
    @include text-label;
    color: $gray-70;
    margin-bottom: 16rem;
}

.rx-page-custom-data-page-upload-4 {
    margin-bottom: 24rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 16rem;
}

.rx-page-custom-data-page-upload-5 {
    margin-top: 16rem;
    margin-bottom: 16rem;
}

.rx-page-custom-data-page-upload-6 {
    ul > li {
        list-style: disc;
        margin-left: 24rem;
    }
}

.rx-page-custom-data-page-upload-7 {
    margin-top: 32rem;
    margin-bottom: 24rem;
}

.rx-page-custom-data-page-upload-8 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.rx-page-custom-data-page-upload-9 {
    width: 36rem;
    height: 36rem;
    border-radius: 50%;
    padding: 4rem;
    margin-right: 16rem;
    background-color: $green-50;
    color: $white;

    &.red {
        background-color: $red-50;
    }
}

.rx-page-custom-data-page-upload-10 {
    margin-top: 24rem;
    color: $red-70;
}