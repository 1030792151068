





@import 'style.scss';


.rx-page-policy-form-area-map-1 {
    position: relative;
    height: 100%;
    margin-top: 1rem;
}

.rx-page-policy-form-area-map-2 {
    @include container;
    display: grid;
    height: 100%;
    overflow: hidden;
    @include mobile {
        min-height: 500rem;
    }
}