





















@import 'style';





.rx-page-custom-data-page-list-1 {
    @include navigation-link;
    @include container;
    padding: 6rem 12rem;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    svg:first-child {
        font-size: 24rem;
        margin-right: 8rem;
    }
}

.rx-page-custom-data-page-list-2 {
    @include table;

    tbody tr > :nth-child(2) {
        padding: 7.5rem 8rem;
    }
}

.rx-page-custom-data-page-list-3 {
    width: 16rem;
    height: 16rem;
    border-radius: 50%;
}

.rx-page-custom-data-page-list-4 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    button {
        margin-right: 16rem;
    }
}