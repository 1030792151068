
























@import 'style.scss';


.rx-page-report-report-1 {
    @include container;
    padding: 24rem;
    display: flex;
    col-gap: 8rem;
}

.rx-page-report-report-2 {
    width: 220rem;
    margin-right: 8rem;
}