






@import 'style';


.rx-common-error-boundary-1 {
	display: flex;
    flex-flow: column nowrap;
	align-items: center;
	justify-content: center;
	width: 100%;
    height: 100%;
    &.large {
        font-size: 32rem;
    }
}