












@import "style";












.rx-page-report-download-buttons-1 {
    margin-top: 8rem;
    display: grid;
    grid-auto-flow: column;
    justify-content: flex-end;
    grid-gap: 8rem;
}