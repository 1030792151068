















@import 'style';


.rx-page-health-page-trip-filter-row-1 {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    position: relative;
    height: 74rem;
    margin: -2rem 0 -2rem -12rem;
    white-space: nowrap;
    > .arrow {
        align-self: stretch;
        width: 40rem;
        height: 100%;
        border-radius: 8rem 0 0 0;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 4rem;
        svg {
            width: 32rem;
            height: 32rem;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 0!important;
        }
    }
    &:disabled > .arrow svg {
        color: $gray-30;
    }
    &:hover:not(:disabled) > .arrow {
        svg {
            border-radius: 50%;
            background-color: $gray-10;
        }
    }
    &:hover:not(:disabled).expanded > .arrow {
        background-color: $gray-10;
    }
}

.rx-page-health-page-trip-filter-row-2 {
    white-space: nowrap;
}

.rx-page-health-page-trip-filter-row-3 {
    text-align: right!important;
    padding-right: 64rem!important;
}