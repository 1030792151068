@use "sass:math";

/*
 * Colors are manually kept in sync with Figma
 * See System Colors in  -> https://www.figma.com/file/DdeJrMgKoQqgisZLrcL25N27/Foundation?node-id=576%3A570
*/
$white: #ffffff;
$gray-10: #f0f0f2;
$gray-30: #dfe3e7;
$gray-50: #c5cdd4;
$gray-60: #8797a6;
$gray-70: #667380;
$gray-90: #222a35;

$green-00: #f3fbf9;
$green-10: #9fe5d5;
$green-30: #57d2b5;
$green-50: #0ea884;
$green-70: #0b8568;
$green-90: #064c3c;

$blue-00: #f5f7fe;
$blue-10: #b1bbf5;
$blue-30: #7688ee;
$blue-50: #3b55e6;
$blue-70: #293ca1;
$blue-90: #18225c;

$red-00: #fff5f5;
$red-10: #ffaead;
$red-30: #fe7170;
$red-50: #fe3432;
$red-70: #b22423;
$red-90: #661514;

$yellow-00: #fffcf2;
$yellow-50: #ffc700;

@mixin scrollbar($color: $green-50) {
    @media screen {
        overflow-y: scroll;
        overflow-x: hidden;
        @include mobile {
            overflow-y: scroll;
            overflow-x: hidden;
        }
        &::-webkit-scrollbar {
            width: 6rem;
            background-color: transparent;
        }

        &::-webkit-scrollbar-thumb {
            background-color: $color;
            border-radius: 6rem;
        }

        &::-webkit-scrollbar-track {
            background-color: rgba(0, 0, 0, 0.1);
        }
    }
}

%link {
    font-weight: bold;
    cursor: pointer;
    color: fade-out($gray-10, 0.2);
    text-decoration: underline;
    &.active {
        text-decoration: none;
        cursor: default;
        color: $blue-90;
        transition: color 150ms;
    }
    &:hover {
        color: $blue-70;
    }
    &:visited {
        color: $blue-30;
    }
}

/*
 * This makes an element as invisible as possible without removing it from
 * the accessibility tree.
 */
@mixin visually-hidden {
    position: absolute !important;
    height: 1rem;
    width: 1rem;
    overflow: hidden;
    clip: rect(1rem, 1rem, 1rem, 1rem);
}

@mixin transition {
    transition: all 150ms;
}

@mixin fill-white {
    background-color: $white;
}

@mixin shadow-2 {
    box-shadow: 0rem 1rem 4rem rgba(0, 0, 0, 0.1),
        0rem 2rem 2rem rgba(0, 0, 0, 0.08);
}

@mixin shadow-hover {
    box-shadow: 0rem 2rem 2rem rgba(0, 0, 0, 0.16),
        0rem 4rem 8rem rgba(0, 0, 0, 0.08), 0rem 4rem 4rem rgba(0, 0, 0, 0.08);
}

@mixin shadow-8 {
    box-shadow: 0rem 1rem 4rem rgba(0, 0, 0, 0.1),
        0rem 4rem 8rem rgba(0, 0, 0, 0.12);
}
@mixin border-bottom-1px {
    box-shadow: inset 0rem -1rem 0rem #dfe3e7;
}
@mixin text-body {
    font-family: Work Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 16rem;
    line-height: math.div(3em, 2);
}

@mixin text-body-tiny {
    @include text-body;
    font-size: 12.8rem;
    line-height: 150%;
}

@mixin text-body-bold {
    font-family: Work Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 16rem;
    line-height: 150%;
}
@mixin text-link {
    font-weight: 500;
}
@mixin text-body-link {
    @include text-body;
    @include text-link;
}
@mixin text-label {
    font-family: Work Sans;
    font-style: normal;
    font-weight: 500;
    font-size: 12.8rem;
    line-height: 150%;
    letter-spacing: math.div(0.25em, 12.8);
}
@mixin text-label-link {
    font-family: Work Sans;
    font-style: normal;
    font-weight: 500;
    font-size: 12.8rem;
    line-height: 150%;
}
@mixin text-label-bold {
    font-family: Work Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 12.8rem;
    line-height: 150%;
    letter-spacing: 0.25rem;
}
@mixin text-body-link-underlined {
    @include text-body;
    @include text-link-underlined;
}
@mixin text-link-underlined {
    @include text-link;
    @media screen {
        text-decoration-line: underline;
    }
}

@mixin text-body-link-underlined-tiny {
    @include text-body-link-underlined;
    font-size: 12.8rem;
    line-height: 150%;
}
@mixin text-body-link-disabled {
    @include text-body-link;
    color: $gray-60;
}
@mixin tooltip {
    line-height: 12rem;
    padding-top: 6rem;
    padding-bottom: 1rem;
    margin-bottom: 4rem;
    @media screen {
        border-bottom: 1rem dashed currentColor;
    }
}
@mixin text-body-tooltip {
    @include text-body-link;
    @include tooltip;
}

@mixin text-caption {
    font-family: Work Sans;
    font-style: normal;
    font-weight: 500;
    font-size: 10.24rem;
    line-height: 18rem;
}
@mixin text-caption-bold {
    font-family: Work Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 10.24rem;
    line-height: 175.95%;
}
@mixin headline-2 {
    font-family: Work Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 48.83rem;
    letter-spacing: -0.5rem;
    line-height: 69rem;
}
@mixin headline-3 {
    font-family: Work Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 39.06rem;
    line-height: 55rem;
}
@mixin headline-4 {
    font-family: Work Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 31.25rem;
    line-height: 44rem;
    letter-spacing: -0.25rem;
}
@mixin headline-5 {
    font-family: Work Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 25rem;
    line-height: 35rem;
    letter-spacing: -0.25rem;
}

@mixin headline-6 {
    font-family: Work Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 20rem;
    line-height: 30rem;
    letter-spacing: -0.25rem;
}

@mixin subhead {
    font-family: Work Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 20rem;
    line-height: 30rem;
}

@mixin navigation-link {
    @include text-body-link;
    transition: color 250ms, background-color 250ms;
    &:not(.disabled) {
        &.active {
            color: $white;
            background-color: $blue-50;
        }
        &:not(.active) {
            &:hover {
                background-color: $gray-10;
            }
        }
    }
    &.disabled {
        color: $gray-50;
    }
}
@mixin link-border {
    border: 1rem solid $gray-30;
}

@mixin not-mobile {
    @media only screen and (min-width: 1189px) {
        @content;
    }
}
@mixin not-mobile-tiny {
    @media only screen and (min-width: 839px) {
        @content;
    }
}
@mixin mobile {
    @media only screen and (max-width: 1188px) {
        @content;
    }
}

@mixin mobile-tiny {
    @media only screen and (max-width: 840px) {
        @content;
    }
}

@mixin container($color: 'gray') {
    background: $white;
    border: 1rem solid $gray-30;
    @if $color == 'blue' {
        border-color: $blue-50;
        color: $blue-50;
        background-color: $blue-00;
    } @else if $color == 'red' {
        border-color: $red-50;
        color: $red-50;
        background-color: $red-00;
    } @else if $color == 'green' {
        border-color: $green-50;
        color: $green-50;
        background-color: $green-00;
    } @else if $color == 'yellow' {
        border-color: $yellow-50;
        color: $gray-90;
        background-color: $yellow-00;
    }
    box-sizing: border-box;
    border-radius: 6rem;
    break-inside: avoid;
}
@mixin banner {
    height: 120rem;
    background: $gray-90;
    border-radius: 6rem;
    color: $white;
    @include headline-4;
    @media print {
        color: $gray-90;
    }
}
@mixin table {
    @include text-body;
    color: $gray-90;

    th,
    td {
        vertical-align: middle;
        &:not(:first-child) {
            padding-left: 8rem;
        }
        &:not(:last-child) {
            padding-right: 8rem;
        }
    }
    thead {
        tr {
            height: 39rem;
            th {
                @include text-label;
                text-align: left;
                color: $gray-70;
                vertical-align: middle;
            }
        }
    }
    tbody {
        tr {
            height: 55rem;
        }
        tr:not(:last-child) {
            border-bottom: 1rem solid $gray-30;
        }
        th {
            @include text-body-bold;
            text-align: left;
        }
    }
    a {
        @include text-link-underlined;
        font-size: 1em;
        @media screen {
            color: $blue-50;
        }
    }

    td > svg {
        margin-top: math.div(-2em, 24);
        margin-bottom: math.div(-7em, 24);
        font-size: math.div(3em, 2);
    }
}

@mixin button {
    @include text-body-link;
    @include shadow-2;
    @include transition;
    text-align: center;
    height: 36rem;
    background-color: $white;
    border: 1rem solid $gray-30;
    border-radius: 6rem;
    box-sizing: border-box;
    color: $gray-90;
    padding: 6rem 12rem;
    text-decoration: none;
    cursor: pointer;

    &.outline-red {
        border: 1rem solid $red-50;
        color: $red-50;
        &:not(:disabled) {
            &:hover {
                background-color: $red-50;
                color: $white;
            }
        }
    }
    &:not(:disabled) {
        &:hover {
            background-color: $gray-30;
            transform: translate(-1rem);
            @include shadow-8;
        }
        &:active {
            box-shadow: none;
            transform: none;
        }
    }
    &.blue,
    &.red {
        border: 1rem solid transparent;
        color: $white;
    }
    &.blue {
        background-color: $blue-50;
        &:not(:disabled):hover:not(:active) {
            background-color: $blue-70;
            color: $white;
            @include shadow-hover;
        }
    }
    &.red {
        background-color: $red-50;
        &:not(:disabled):hover:not(:active) {
            background-color: $red-70;
            color: $white;
            @include shadow-hover;
        }
    }
    &:disabled {
        background-color: $gray-30;
        color: $gray-60;
        border-color: $gray-30;
        box-shadow: none;
    }
}

@mixin forms-text-field {
    @include text-body;
    @include container;
    display: block;
    padding: 5rem 12rem;
    transition: all 150ms;
    color: $gray-90;
    &::placeholder {
        color: $gray-60;
    }
    &:hover {
        border-color: $gray-60;
    }
    &:focus {
        border-color: $blue-50;
        &::placeholder {
            color: $gray-30;
        }
    }
    &.warning:not(.error) {
        border-color: $yellow-50;
    }
    &.error {
        border-color: $red-50;
    }
}

@mixin forms-number-field {
    @include forms-text-field;
    padding-right: 2rem;
    padding-left: 8rem;
    transition: 150ms all ease-in-out;

    &:disabled {
        background-color: $gray-10;
        border-color: $gray-50;
        color: $gray-50;
    }
}

@mixin donut-chart($fill, $percentage) {
    $circle-start: -1turn / 8;
    width: 200rem;
    height: 100rem;
    position: relative;
    overflow: hidden;
    box-sizing: border-box;
    &:after {
        content: '';
        width: 200rem;
        height: 200rem;
        border: 40rem solid;
        border-color: rgba(0, 0, 0, 0.12) rgba(0, 0, 0, 0.12) $fill $fill;
        position: absolute;
        border-radius: 50%;
        left: 0;
        top: 0;
        box-sizing: border-box;
        transform: rotate($circle-start + ($percentage / 100 * 0.5turn));
        animation: fillAnimation 1s ease-out;
    }
    @-webkit-keyframes fillAnimation {
        0% {
            transform: rotate($circle-start);
        }
    }

    @keyframes fillAnimation {
        0% {
            transform: rotate($circle-start);
        }
    }
}

.tippy-box[data-theme~='ride-report-dark'] {
    border-radius: 3rem;
    background-color: $gray-90;
    color: $white;
    padding: 8rem;
}

@mixin blinking-animation {
    -webkit-animation: 1s blink ease 3;
    -moz-animation: 1s blink ease 3;
    animation: 1s blink ease 3;
    animation-delay: 1s;

    @-moz-keyframes blink {
        from,
        to {
            opacity: 1;
        }
        50% {
            opacity: 0;
        }
    }

    @-webkit-keyframes blink {
        from,
        to {
            opacity: 1;
        }
        50% {
            opacity: 0;
        }
    }

    @keyframes blink {
        from,
        to {
            opacity: 1;
        }
        50% {
            opacity: 0;
        }
    }
}

/*
----------- TOGGLES -----------

A toggle is an input of type "check" or "radio" that has had the native box
hidden and replaced with a custom-styled one of our own.

For accessibility reasons, we need to insure that there is a real (hidden) input
that is driving all of the states, which is why there's all this effort to make
all the selectors start from the input element.
*/

/*
Toggles can be re-themed to match certain colors (such as operator colors), and
use the following three css variables to determine color.

CSS variables are scoped by parents and determined at runtime. Here we place
the default colors in html so all children have access to them.
*/
html {
    --ride-report-inactive-toggle-color: #{$gray-70};
    --ride-report-active-toggle-color: #{$blue-50};
    --ride-report-hover-toggle-color: #{$blue-30};
}

/*
Fetching a nonexistent CSS variable won't throw any errors, which can make
debugging confusing. You can use these sass variables instead, which will
complain at compile-time if you make a typo.
*/
$inactive-toggle: var(--ride-report-inactive-toggle-color);
$active-toggle: var(--ride-report-active-toggle-color);
$hover-toggle: var(--ride-report-hover-toggle-color);

/*
There's one part of the toggle that works nearly consistently between all of,
them but that part might be the whole label itself, a div inside it, or
an :after element.

Giving a selector that describes how to find it starting from the input
(like ' + label' or ' + label > svg'), this will set that input up with all the
default styles, including for :focus, :hover, :checked, :disabled states.
*/
@mixin toggle-defaults($location-from-input) {
    position: absolute;
    opacity: 0;
    width: 0;
    margin: 0;
    padding: 0;
    overflow: hidden;
    & + label {
        @include text-body-link;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        color: $gray-90;
        user-select: none;
        position: relative;
    }
    &#{$location-from-input} {
        transition: 150ms all;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4rem;
        background-color: $white;
        border: 1rem solid $inactive-toggle;
        font-size: 20rem;
        margin-right: 8rem;
    }
    &:not(:disabled) {
        &:hover,
        &:focus {
            &#{$location-from-input} {
                box-shadow: 0rem 0rem 4rem $hover-toggle;
            }
            &:not(:checked)#{$location-from-input} {
                border-color: $hover-toggle;
            }
        }
    }
    &:checked#{$location-from-input} {
        border-color: transparent;
        background-color: $active-toggle;
    }
    &:disabled#{$location-from-input} {
        border-color: transparent;
        background-color: $gray-30;
    }
}
