











@import 'style.scss';


.rx-common-tag-1 {
    display: inline-block;
    background-color: black;
    @include text-body-link;

    text-decoration: none!important;
    color: white;
    padding: 2.5rem 12rem;
    border-radius: 20rem;
    @media print {
        background-color: transparent!important;
        border: 1rem solid $gray-60;
        color: $gray-90!important;
        padding: 1.5rem 11rem;
    }
    &a:hover {
        // the easiest way to darken the background programatically is to add
        // another semi-transparent background layer
        // here we use a linear-gradient transitioning too and from the same value
        background-image: linear-gradient(to right, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
    }
    &.disableColor {
        background-color: white;
        color: black;
    }
}