





@import 'style';


.rx-page-segment-editor-segment-editor-filter-panel-1 {
    @include shadow-2;
    background-color: $gray-10;
    position: absolute;
    left: auto;
    height: 100%;
    width: 272rem;
    z-index: 99;
    pointer-events: auto;
}

.rx-page-segment-editor-segment-editor-filter-panel-2 {
    @include text-body;
    display: flex;
    font-weight: 600;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-radius: 6rem;
    background-color: $gray-90;
    color: $gray-60;
    height: 56rem;
    display: flex;
    padding: 0 8rem;
    margin: 9rem;
}

.rx-page-segment-editor-segment-editor-filter-panel-3 {
    @include subhead;
    font-weight: 600;
    color: $gray-70;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16rem 8rem;
    height: 62rem;
    margin-right: 9rem;
    margin-left: 9rem;
}

.rx-page-segment-editor-segment-editor-filter-panel-4 {
    height: calc(100% - 137rem); // account for other elements above
    padding-left: 9rem;
    padding-right: 10rem;
    overflow-y: auto;
    overflow-x: hidden;
    padding-bottom: 24rem;
}