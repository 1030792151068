














@import 'style';


.rx-page-policy-forms-form-minimum-deployment-1 {
    display: flex;
    align-items: center;
    grid-gap: 16rem;
}