

































@import 'style.scss';


.rx-page-explore-page-heatmaps-hex-bins-index-1 {
    display: grid;
}