




















    @import 'style';


.rx-page-policy-page-dashboard-1 {
    display: grid;
    grid-template-columns: 2fr minmax(300rem, 1fr);
    @include mobile {
        grid-template-columns: 1fr;
    }
    grid-gap: 32rem;
    align-items: start;
}

.rx-page-policy-page-dashboard-2 {
    display: flex;
    flex-direction: column;
    row-gap: 32rem;
    column-gap: 32rem;
}

.rx-page-policy-page-dashboard-3 {
    @include text-body-link-underlined;
    color: $blue-50;
}