






















@import "style";
@import "~common/date-picker-styles";


.rx-page-report-report-picker-weekly-1 {
    @include calendar-grid;
    @include calendar-wrapper;
    grid-template-columns: auto;
    @include calendar;
}

.rx-page-report-report-picker-weekly-2 {
    border-radius: 16rem;
    @include calendar-grid;
    color: $gray-90;
}

.rx-page-report-report-picker-weekly-3 {
    @include calendar-cell;
    color: inherit;
}