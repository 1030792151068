




































@import 'style.scss';


.rx-common-area-map-1 {
    overflow: hidden;
}

.rx-common-area-map-2 {
    position: relative;
    height: 694rem;
    display: grid;
    overflow: hidden;
    /* undo the container's padding. I found this easier that making new versions
     * of Container and ContainerHeader */
    margin: -24rem;
    @include mobile {
        height: calc(100vw - 112rem);
        max-height: 500rem;
    }
    &.tiny {
        height: calc(48rem + 100%);
    }

}