



@import 'style.scss';







.rx-common-key-value-1 {
    @include text-body;
}




.rx-common-key-value-2 {
    & > *:not(:last-child) {
        margin-bottom: 16rem;
    }
}

.rx-common-key-value-3 {
    @include text-body-bold;
    &:after {
        content: ": ";
    }
}