






























@import 'style.scss';


.rx-page-fees-page-list-1 {
    @include table;
    width: 100%;
}

.rx-page-fees-page-list-2 {
    @include headline-2;
    color: $gray-90;

    display: grid;
    grid-template-columns: repeat(auto-fill, 160rem);
    grid-gap: 20rem 65rem;
    padding: 0 8rem;
    max-width: 1150;
    li {
        &:before {
            display: inline;
        }
    }
    min-height: 89rem;
}

.rx-page-fees-page-list-3 {
    margin-top: -4rem;
    @include text-body-link;
    color: $gray-60;
}
.rx-page-fees-page-list-4 {;
    display: block;
    max-width: 160rem;
    margin-top: -4rem;
    @include text-body-link;
    color: $blue-50;
    &:hover {
        text-decoration: underline;
    }
}

.rx-page-fees-page-list-5 {
    background-color: $gray-90;
    width: 120rem;
    height: 37rem;
    margin-top: 15rem;
    position: relative;
    border-radius: 3rem;
    &:after {
        content: '';
        position: absolute;
        background-color: $gray-60;
        width: 80rem;
        height: 17rem;
        top: 52rem;
        border-radius: 3rem;
    }
}