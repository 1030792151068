



























@import 'style';


.rx-page-health-page-trip-filter-new-row-1 {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    margin-top: -25rem;
}

.rx-page-health-page-trip-filter-new-row-2 {
    margin: 32rem 8rem 8rem 8rem;
    flex: 0 0 auto;
}



























































































.rx-page-health-page-trip-filter-new-row-3 {
    &:not(:focus-within):not(:hover) > .imperial {
        display: none;
    }
    > .imperial {
        @include text-label;
        color: $gray-70;
        margin-left: 8rem;
    }
    height: 79.2rem;
    margin-bottom: -19.2rem;
    flex: 0 1 104rem;
}