
























@import 'style.scss';


.rx-page-fees-page-details-1 {
    @include table;
    tfoot {
        td {
            @include text-body-bold;
            padding: 40rem 8rem 16rem 8rem;
        }
        th {
            @include text-body;
            padding: 40rem 8rem 16rem 8rem;
            text-align: left;
        }
    }
}

.rx-page-fees-page-details-2 {
    @include text-body-link-underlined;
    color: $blue-50;
}

.rx-page-fees-page-details-3 {
    @include text-body-bold;
    padding-top: 40rem;
}