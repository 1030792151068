













@import 'style';


.rx-page-explore-page-heatmaps-routes-download-modal-1 {
    max-width: 480rem;
}

.rx-page-explore-page-heatmaps-routes-download-modal-2 {
    @include headline-5;
    color: $gray-90;
    padding-bottom: 16rem;
}

.rx-page-explore-page-heatmaps-routes-download-modal-3 {
    @include text-body-link-underlined;
    color: $blue-50;
    padding: 24rem 0;
    &.first-child{
        margin-bottom: 8rem;
    }
}

.rx-page-explore-page-heatmaps-routes-download-modal-4 {
    display: flex;
    align-items: center;
    border-radius: 6rem;
    padding: 6rem 12rem;
    margin-bottom: 4rem;
    &:hover {
        background-color: $gray-10;
        cursor: pointer;
    }
    &:active {
        background-color: $gray-30;
    }
    svg {
        margin-right: 4rem;
        font-size: 20rem;
    }
}

.rx-page-explore-page-heatmaps-routes-download-modal-5 {
    @include text-body-tiny;
}

.rx-page-explore-page-heatmaps-routes-download-modal-6 {
    @include text-body-link-underlined-tiny;
    color: $blue-50;
}

.rx-page-explore-page-heatmaps-routes-download-modal-7 {
    display: flex;
    justify-content: flex-end;
    padding-top: 24rem;
}