






























    @import 'style';


.rx-page-policy-new-policy-form-1 {
    margin-right: 1rem;
}

.rx-page-policy-new-policy-form-2 {
    display: grid;
    grid-template-columns: minmax(400rem, 1fr) minmax(400rem, 1fr);
    grid-gap: 16rem;
    @include mobile {
        grid-template-columns: 1fr 1fr;
    }

    > section {
        height: fit-content;
    }
}

.rx-page-policy-new-policy-form-3 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.rx-page-policy-new-policy-form-4 {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    align-content: center;
    justify-content: space-between;
}

.rx-page-policy-new-policy-form-5 {
    @include text-link-underlined;
    color: $blue-50;
    display: flex;
    justify-content: flex-end;
    margin: 24rem 0 0 0;
    > div {
        width: 208rem;
    }
}

.rx-page-policy-new-policy-form-6 {
    margin-bottom: 18px;
}