



































@import '../style.scss';





















.rx-page-health-page-trip-filter-1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    > * {
        margin-left: 8rem;
    }
}

















































.rx-page-health-page-trip-filter-2 {
    @include table;
    border-collapse: separate;
    tbody tr > * {
        height: 72rem;
        vertical-align: center;
        border-color: transparent;
        border-style: solid;
        border-width: 0;
    }
    tbody tr > *:first-child {
        @include text-body-bold;

        svg {
            font-size: 24rem;
            margin-right: 16rem;
        }
    }
    td:last-child {
        box-sizing: content-box;
        width: 164rem;
    }
    tr.sub-row td:first-child {
        text-align: right;
    }
    tbody {
        tr > *:first-child {
            border-left-width: 1rem;
        }
        tr > *:last-child {
            border-right-width: 1rem;
        }
        tr:first-child {
            > * {
                border-top-width: 1rem;
                border-bottom-width: 1rem;
            }
            > *:first-child {
                padding-left: 12rem;
                border-left-width: 1rem;
                border-top-left-radius: 8rem;
            }
            > *:last-child {
                border-right-width: 1rem;
                border-top-right-radius: 8rem;
            }
        }
        tr:last-child {
            > * {
                border-bottom-width: 1rem;
            }
            > *:first-child {
                border-left-width: 1rem;
                border-bottom-left-radius: 8rem;
            }
            > *:last-child {
                border-right-width: 1rem;
                border-bottom-right-radius: 8rem;
            }
        }
    }
    tbody.expanded tr > * {
        border-color: $gray-30;
    }
}
.rx-page-health-page-trip-filter-3 {
    width: 100%;
    display: flex;
    flex-flow:  row nowrap;
    justify-content: space-between;
    align-items: center;
}
.rx-page-health-page-trip-filter-4 {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    > * {
        margin-left: 8rem;
    }
    margin-left: -8rem;
}