











@import "style";





.rx-page-report-report-picker-1 {
    display: grid;
    grid-template-columns: 140rem 294rem;
    grid-column-gap: 8rem;
}
.rx-page-report-report-picker-2 {
    margin-bottom: 5rem;
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    padding: 0 12rem 0 4rem;
    border-right: 1rem solid $gray-30;
}
.rx-page-report-report-picker-3 {
    @include text-body-link;
    color: $gray-90;
    transition-property: background-color;
    transition-duration: 100ms;
    border-radius: 6rem;
    padding: 8rem 12rem;
    &:disabled {
        background-color: $blue-50;
        color: white;
    }
    &:not(:disabled):hover {
        background-color: $gray-30;
    }
}
.rx-page-report-report-picker-4 {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    position: relative;
}