








@import 'style.scss';


.rx-page-explore-common-sidebar-custom-datasets-1 {
    @include container;
    position: absolute;
    padding: 12rem;
    top: 40rem;
    @include shadow-8;
    display: flex;
    flex-flow: column nowrap;
    z-index: 1;
    transition: 100ms all;
    &.enter {
        transition-timing-function: ease-out;
    }
    &.exit {
        transition-duration: 200ms;
        transition-timing-function: ease-in;
    }
    &.enter, &.exit.exit-active {
        opacity: 0;
        transform: translateY(-40rem);
    }
    &.enter.enter-active, &.exit {
        opacity: 1;
        transform: translateY(0rem);
    }
}