








@import 'style.scss';








.rx-common-error-message-1 {
    display: grid;
    justify-items: center;
    align-items: center;
    grid-gap: 16rem;
    padding: 16rem;
    @include text-body;
}

















































































.rx-common-error-message-2 {
    color: $red-50;
    @include text-label;
    title {
        display: inline;
        font-size: 1em;
        font-weight: bold;
    }
}