









































































































































.rx-common-icon-1 {
    height: 1em;
    width: 1em;
    fill: currentColor;
    &.half {
        width: .5em;
    }
}