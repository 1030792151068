









@import 'style';


.rx-page-custom-data-color-picker-1 {
    position: absolute;
    background-color: $white;
    z-index: 1;
    border: 1rem solid $gray-30;
    border-radius: 6rem;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(40px, 1fr));
    max-width: 280rem;
    padding: 8rem;
}

.rx-page-custom-data-color-picker-2 {
    cursor: pointer;
    width: 25rem;
    height: 25rem;
    border-radius: 50%;
    margin-right: 8rem;
    pointer-events: auto;
    &.isPalette {
        margin: 8rem;
        transition: transform .25s ease;
        &:hover {
            transform: scale(1.3);
        }
    }
}

.rx-page-custom-data-color-picker-3 {
    color: $white;
    font-size: 25rem;
}