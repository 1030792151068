





    @import 'style.scss';




.rx-common-select-1 {
    @include text-label;
}