



@import 'style';


.rx-page-custom-data-preview-csv-1 {
    @include table;

    tbody tr > :nth-child(2) {
        padding: 7.5rem 8rem;
    }
}