
















@import 'style.scss';


.rx-page-report-report-area-banner-1 {
    display: grid;
    grid-template-columns: 1fr;
     grid-auto-flow: column;
    grid-auto-columns: auto;
    grid-gap: 24rem;
    h2 {
        @include headline-4;
    }
}
.rx-page-report-report-area-banner-2 {
    @include text-label-bold;
    display: grid;
    grid-template-rows: 1fr auto;
    > :first-child {
        border: none;
    }
    > :last-child {
        margin: 4rem;
    }
    border: 1rem solid $gray-30;
    border-radius: 3rem;
    position: relative;
    &:hover:after {
        content: "";
        z-index: 2;
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 3rem;
        background-color: $gray-90;
        opacity: 0.1;
    }
    a.mapboxgl-ctrl-logo {
        height: 23rem !important;
        background-size: cover;
        margin: 0 0 -4rem -4rem !important;
        overflow: visible !important;
        &.mapboxgl-compact {
            width: 23rem !important;
        }
    }
    .mapboxgl-ctrl-bottom-left .mapboxgl-ctrl {
        margin: 0 0 8rem 8rem !important;
    }
}