










@import 'style';


.rx-root-navigation-menu-1 {
    position: relative;
    width: 64px;
    @include mobile-tiny {
        width: 0px;
    }
    z-index: 12;
}

.rx-root-navigation-menu-2 {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    z-index: 2;
    background-color: $white;
    @include border-bottom-1px;
    transition: all 150ms;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include not-mobile-tiny {
        &:hover {
            width: 160px;
        }
        width: 64px;
    }
    @include mobile-tiny {
        position: fixed;
        width: 100vw;
        left: -100vw;
        top: 64px;
        height: calc(100vh - 64px);
        &.show {
            left: 0;
        }
    }
}

.rx-root-navigation-menu-3 {
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-flow: row;
    grid-gap: 20px;
    align-content: start;
    justify-items: stretch;
    padding: 14px;
}

.rx-root-navigation-menu-4 {
    @include navigation-link;
    font-size: 16px;
    transition: all 150ms;
    overflow: hidden;
    border-radius: 4px;
    height: 36px;
    padding: 0 6px;
    display: grid;
    grid: 1fr / auto auto;
    grid-gap: 8px;
    align-items: center;
    justify-content: start;
    svg {
        color: $gray-60;
    }
    &.active svg {
        color: $white;
    }
}
.rx-root-navigation-menu-5 {
    font-size: 24px;
}