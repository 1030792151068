@use "sass:math";
@import 'style';

@font-face {
    font-family: 'PT-Mono';
    font-style: normal;
    font-weight: 700;
    src: local('PT Mono'), local('PT-Mono'),
        url(assets/fonts/PTMono-Regular.woff2) format('woff2'),
        url(assets/fonts/PTMono-Regular.woff) format('woff');
}

td {
    vertical-align: middle !important;
}

body {
    // if this isn't included on the body, the assembly.css will override it
    font-family: Work Sans, 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16rem;
    line-height: 24rem;
    width: 100vh;
}

body,
#app {
    height: 100%;
    width: 100%;
    overflow: hidden;
    @media print {
        height: auto;
    }
}

#app {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: space-between;
    overflow: hidden;
    @media print {
        display: block;
    }
}

* {
    box-sizing: border-box;
}

@media print {
    nav {
        display: none !important;
    }
}

html {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    background-color: $gray-10;
    /* We use `rem` units for defining measurements across the app so that we
    can change the scaling of the whole screen dynamically. In order to make it
    easy to calculate how many `rem` a given measurement should be, we set the
    base font size to `1px` so that there's a 1:1 rem:px relationship. */
    font-size: 1px;
}
/* For smaller screen sizes, we scale the font size downwards proportional to
the screen width. This way, we only need to design the app to look good at 760px
or larger and smaller screen sizes will shrink to fit. */
@media only screen and (max-width: 760px) {
    html {
        font-size: math.div(1, 760) * 100vw;
    }
}

@media print {
    html {
        height: auto;
        overflow: auto;
    }
}
#ie-error {
    height: 100%;
    padding: 80rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 40rem;
    line-height: normal;
    text-align: center;
}

#app > header {
    height: 54rem;
    flex: 0 0 auto;
    background-color: $gray-90;
    padding: 14rem 16rem;
    display: flex;
    position: relative;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    @include mobile-tiny {
        height: 64px;
        padding: 14px 8px;
    }
}

#app > header img.logo {
    &.icon {
        display: none;
        @include mobile-tiny {
            box-sizing: border-box;
            display: block;
            position: relative;
            z-index: 1;
            padding-right: 8px;
            width: auto;
            height: 35px;
        }
        @media print {
            display: none;
        }
    }
    &.full {
        max-height: 100%;
        box-sizing: border-box;
        display: block;
        width: auto;
        height: auto;
        position: relative;
        z-index: 1;
        @include mobile-tiny {
            display: none;
        }
        @media print {
            display: none;
        }
    }
    &.print {
        display: none;
        @media print {
            max-height: 100%;
            display: block;
            box-sizing: border-box;
            display: block;
            width: auto;
            height: auto;
            position: relative;
            z-index: 1;
        }
    }
}
