








    @import 'style';


.rx-page-policy-policy-selector-1 {
    @include container;
    height: 176rem;
    padding: 24rem;
    transition: all 100ms ease-in-out;
    &:not(.disabled):hover {
        border-color: $blue-50;
        background-color: $blue-00;
        cursor: pointer;
    }
    &.disabled {
        background-color: $gray-10;
        color: $gray-70;
        pointer-events: none;
    }
}

.rx-page-policy-policy-selector-2 {
    @include headline-5;
}

.rx-page-policy-policy-selector-3 {
    margin-top: 8rem;
    > a {
        @include text-body-link-underlined;
        pointer-events: auto;
        color: $blue-50;
    }
}