





































@import 'style';


.rx-page-mode-shift-index-1 {
    @include text-link-underlined;
    color: $blue-50;
}

.rx-page-mode-shift-index-2 {
    color: $gray-70;
    @include subhead;
}

.rx-page-mode-shift-index-3 {
    @include container;
    padding: 24rem;
    display: flex;
    flex-flow: row wrap;
    gap: 37rem 8rem;
}
.rx-page-mode-shift-index-4 {
    margin-left: -24rem;
    display: grid;
    grid-template-columns: auto auto auto;
}