






@import 'style.scss';


.rx-page-policy-forms-time-select-1 {
    width: 130rem;
}