

















@import 'style.scss';


.rx-page-health-health-summary-page-1 {
    @include text-body;
    color: $gray-60;
}

.rx-page-health-health-summary-page-2 {
    display: flex;
    justify-content: space-between;
    padding-bottom: 8rem;
    color: $white;
}

.rx-page-health-health-summary-page-3 {
    margin-bottom: 24rem;
    &:last-child {
        margin-bottom: 0rem;
    }
}

.rx-page-health-health-summary-page-4 {
    @include container;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    gap: 0rem 0rem;
    & > div {
        @include text-link;
        color: $gray-90;
        padding: 16rem 24rem;
    }
    & > div > div {
        margin-top: 6rem;
    }
    & > div:not(:last-child) {
        border-right: 1rem solid $gray-30;
    }
}

.rx-page-health-health-summary-page-5 {
    height: 12rem;
    width: 12rem;
    fill: $green-50;
    border-radius: 50%;
    position: relative;
    margin-right: 6rem;
    &.issues {
        fill: $red-50;
        @include blinking-animation;
    }
    &.no-feed {
        fill: $gray-60;
    }
}