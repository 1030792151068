



















@import "style";
@import "./date-picker-styles";


.rx-common-date-picker-1 {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    padding: 0 8rem;
}

.rx-common-date-picker-2 {
    @include transition;
    @include text-label;
    border-radius: 6rem;
    width: 32rem;
    height: 32rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1rem solid $gray-30;
    box-sizing: border-box;
    color: $gray-70;
    font-size: 20rem;
    opacity: 0;
    cursor: default;
    &:not(:disabled) {
        opacity: 1;
        @include shadow-2;
        &:hover {
            @include shadow-hover;
        }
    }
}

.rx-common-date-picker-3 {
    display: grid;
    grid-auto-flow: column;
    grid-gap: 8rem;
}

.rx-common-date-picker-4 {
    margin-top: 16rem;
    margin-bottom: 16rem;
    display: flex;
    gap: 16rem;
    flex-wrap: wrap;

    button {
        color: $gray-70;
    }
}











































































































































.rx-common-date-picker-5 {
    @include calendar-cell;
    color: $gray-60;
}

























.rx-common-date-picker-6 {
    display: flex;
    flex-flow: row nowrap;
}
.rx-common-date-picker-7 {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 0 7rem;
    svg {
        margin-right: 8rem;
        color: $yellow-50;
    }
    @include text-label;
}

.rx-common-date-picker-8 {
    &:not(:first-child) {
        padding-left: 5rem;
    }
    &:not(:last-child) {
        padding-right: 5rem;
        border-right: 1rem solid $gray-30;
    }
}

.rx-common-date-picker-9 {
    @include calendar;
    @include calendar-grid;
    padding: 13rem;
}
.rx-common-date-picker-10 {
    @include calendar-grid;
}
.rx-common-date-picker-11 {
    @include calendar-cell;
}
.rx-common-date-picker-12 {
    margin: 0 -5rem;
    background-color: $gray-30;
    &.left {
        margin-left: 0;
        border-top-left-radius: 16rem;
        border-bottom-left-radius: 16rem;
    }
    &.right {
        margin-right: 0;
        border-top-right-radius: 16rem;
        border-bottom-right-radius: 16rem;
    }
    &.continue-right, &.continue-left {
        border-radius: 0;
    }
    @mixin continue-gradient($direction) {
        background: linear-gradient(to $direction, $gray-30, fade-out($gray-30, 1));
    }
    &.continue-left {
        @include continue-gradient(left);
    }
    &.continue-right {
        @include continue-gradient(right);
    }
}






























































































































































































































































































































































































































































































































































.rx-common-date-picker-13 {
    display: flex;
    flex-flow: row nowrap;
    > * {
        flex: 1 0 auto;
        margin: 8rem 8rem 0 0;
    }
    > button {
        flex: 0 0 auto;
    }
    margin: -8rem -8rem 0 0;
}

.rx-common-date-picker-14 {
    display: flex;
    align-items: center;
    padding: 6rem;
    padding-left: 36rem;
    position: relative;
    white-space: nowrap;
    svg {
        position: absolute;
        font-size: 24rem;
        top: calc(50% - 24rem / 2);
        left: 7rem;
        pointer-events: none;
        color: $blue-50;
    }
}