










@import 'style';


.rx-page-explore-common-sidebar-sidebar-1 {
    @include not-mobile-tiny {
        @include container;
        grid-template-columns: 220rem;
        max-height: calc(100% - 106rem);
        justify-self: end;
        align-self: start;
    }
    @include mobile-tiny {
        width: 100%;
        // override container styling, so we have a flat top on mobile
        border-radius: 0 0 6rem 6rem;
    }
    color: $gray-60;
    display: grid;
    grid-template-rows: auto 1fr;
    position: relative;
    min-height: 0;
	z-index: 1;
    pointer-events: auto;
    transition: 150ms all ease-in-out;
    left: 0;
    opacity: 1;
    &.exit, &.enter:not(.enter-active) {
        left: calc(268rem);
    }
    @media (prefers-reduced-motion: reduce) {
        &.exit, &.enter:not(.enter-active) {
            left: 0;
            opacity: 0;
        }
    }
    &.auto-height {
        height: auto
    }
}

.rx-page-explore-common-sidebar-sidebar-2 {
    padding: 16rem;
    display: grid;
    grid-template-columns: auto auto;
    justify-content: start;
    grid-gap: 8rem;
}

.rx-page-explore-common-sidebar-sidebar-3 {
    box-sizing: border-box;
    padding: 0rem 16rem 16rem;
    display: grid;
    grid-template-columns: 100%;
    grid-auto-flow: row;
    grid-gap: 16rem;

    @include scrollbar($color: $green-50);
}