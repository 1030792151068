















@import 'style';















.rx-common-user-masquerade-selector-1 {
    display: flex;
    flex-flow: row wrap;
}

.rx-common-user-masquerade-selector-2 {
    position: relative;
    break-inside: avoid;
    padding: 4rem 8rem;
}

.rx-common-user-masquerade-selector-3 {
    @include text-label-bold;
    padding: 16rem 0 8rem 0;
}



















































.rx-common-user-masquerade-selector-4 {
    @include text-label;
    color: $gray-70;
}

.rx-common-user-masquerade-selector-5 {
    text-align: center;
}

.rx-common-user-masquerade-selector-6 {
    @include text-body;
    color: $gray-70;
}