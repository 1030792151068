













@import 'style';


.rx-root-page-home-1 {
    @extend %link;
    display: block;
    font-size: 16rem;
    color: $blue-50;
}

.rx-root-page-home-2 {
    display: grid;
    grid: auto-flow / 1fr;
    row-gap: 16rem;
}

.rx-root-page-home-3 {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row;
    align-items: center;
    justify-content: space-between;
    padding: 16rem 24rem;
    min-height: 64rem;
    background: $white;
    border: $gray-30 1rem solid;
    box-sizing: border-box;
    border-radius: 6rem;
    &:last-child {
        margin-bottom: 64rem;
    }
    &.alert {
        background: $red-00;
        border: 1rem solid $red-50;
    }
    @include mobile{
        padding: 8rem 12rem;
    }
}

.rx-root-page-home-4 {
    display: grid;
    grid-template-columns: auto auto;
    grid-column-gap: 12rem;
    align-items: center;
    position:relative;
    &.alert {
        color: $red-50;
    }
    @include mobile {
        grid-column-gap: 6rem;
    }
}

.rx-root-page-home-5 {
    font-size: 20rem;
    @include mobile{
        font-size: 18rem;
        margin-right: 4rem;
    }
}

.rx-root-page-home-6 {
    height: 12rem;
    width: 12rem;
    fill: $green-50;
    border-radius: 50%;
    position: relative;
    &.alert {
        fill: $red-50;
        @include blinking-animation;
    }
}

.rx-root-page-home-7 {
    @include button;
}