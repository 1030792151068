





@import 'style';


.rx-page-explore-common-sidebar-sidebar-section-1 {
    color: $gray-60;
    @include text-label-bold;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
}

.rx-page-explore-common-sidebar-sidebar-section-2 {
    display: grid;
    grid: auto-flow / 1fr;
    grid-gap: 14rem;
    min-width: 0;
    & > * {
        min-width: 0;
    }
}

.rx-page-explore-common-sidebar-sidebar-section-3 {
    position: relative;
    width: 40rem;
    @include text-body;
}

.rx-page-explore-common-sidebar-sidebar-section-4 {
    padding: 0 4rem;
    border-radius: 2rem;
    background-color: $green-50;
    color: $white;
    margin-left: auto;
}

.rx-page-explore-common-sidebar-sidebar-section-5 {
    margin-right: 8rem;
}