






@import '../style.scss';


.rx-common-compliance-score-1 {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.rx-common-compliance-score-2 {
    @include text-label-bold;
    padding-top: 6rem;
    padding-bottom: 6rem;
    width: 40rem;
    margin-right: 4rem;
    text-align: center;
    border-radius: 4rem;

    &.green {
        color: $green-70;
        background-color: $green-00;
    }
    &.red {
        color: $red-70;
        background-color: $red-00;
    }
}

.rx-common-compliance-score-3 {
    @include text-caption;
    flex-grow: 1;
    color: $gray-70;
    margin-top: auto;
    margin-bottom: auto;

    &.hovered {
        color: $gray-90;
    }
}