































@import 'style.scss';


.rx-root-page-settings-1 {
    @include table;
    width: 100%;
    table-layout: fixed;

    thead td {
        width: 44rem;
    }
    thead th button {
        cursor: initial;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        svg {
            font-size: 24rem;
        }
    }
    tbody td:nth-child(2) div {
        justify-items: start;
    }
}