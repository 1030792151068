















@import 'style.scss';








































































































.rx-page-analyze-metric-chart-legend-1 {
    display: flex;
    align-items: flex-start;
    margin-right: 16rem;
    margin-top: 8rem;
    & > *:not(:last-child) {
        margin-top: 11rem;
        margin-right: 16rem;
    }
    & > p {
        margin-right: 16rem;
    }
}

.rx-page-analyze-metric-chart-legend-2 {
    display: block;
    height: 1rem;
    border: 0;
    margin: 1em 0;
    padding: 0;
    width: 100rem;
    margin-right: 16rem;
    border-top-width: 3rem;
}

.rx-page-analyze-metric-chart-legend-3 {
    @include tooltip;
}






































































































































































































.rx-page-analyze-metric-chart-legend-4 {
    @include tooltip;
    @include text-body-bold;
    &:after {
        content: ": ";
    }
}