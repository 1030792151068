






































@import 'style';



































.rx-page-health-page-trip-filter-new-1 {
    @include table;
    border-collapse: separate;
    td, th {
        vertical-align: middle;
    }
    thead th {
        box-sizing: content-box;
        padding-bottom: 15rem;
    }
    tbody tr {
        > * {
            height: 88rem;
            vertical-align: center;
            border-color: transparent;
            border-style: solid;
            border-width: 0;
            &:first-child {
                @include text-body-bold;
                white-space: nowrap;
                svg {
                    font-size: 24rem;
                    margin-right: 16rem;
                }
            }
        }
    }
    td:last-child {
        box-sizing: content-box;
        width: 164rem;
    }
    tr.sub-row td:first-child {
        text-align: right;
    }
    max-width: 100%;
}