











































    @import 'style';


.rx-page-explore-page-heatmaps-curb-events-index-1 {
    @include text-body-tiny;
    height: 19.2rem;
    color: $red-70;
}