




























@import 'style.scss';


.rx-page-explore-page-heatmaps-ridesheds-rideshedMetadataPanel-1 {
    display: flex;
    flex-direction: column;
    gap: 16rem;
}