


@import 'style.scss';


.rx-page-explore-common-overlay-1 {
    position: absolute;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    display: grid;
    grid-template-columns: auto 1fr;
    grid-auto-flow: column;
    grid-template-rows: 1fr;
    grid-gap: 16rem;
    padding: 16rem 16rem 32rem 16rem;
    pointer-events: none;
    z-index: 11;
    align-items: start;
}