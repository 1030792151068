


@import 'style.scss';


.rx-page-explore-common-map-container-1 {
    @include container;
    @include shadow-8;
    padding: 16rem;
    pointer-events: auto;
    min-width: 280rem;
    display: grid;
    grid-gap: 16rem;
    &.stand-alone {
        background: $gray-90;
        padding-top: 0rem;
    }
    label {
        @include text-label;
        color: $gray-70;

        transition: 150ms color ease-in-out;

        &.disabled {
            color: $gray-60;
        }
    }
}