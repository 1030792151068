


































@import 'style.scss';




















































































.rx-page-policy-policy-1 {
    color: $gray-70;
    &:after {
        content: ": ";
    }
}
.rx-page-policy-policy-2 {
    color: $gray-90;
}

.rx-page-policy-policy-3 {
    float: right;
    text-align: right;
}

.rx-page-policy-policy-4 {
    @include headline-3;
}

.rx-page-policy-policy-5 {
    @include text-body-link;
    color: $gray-60;
    margin-top: -4rem;
}

.rx-page-policy-policy-6 {
    display: inline;
}





















































































































































































.rx-page-policy-policy-7 {
    & > *:not(:last-child) {
        margin-bottom: 24rem;
    }
}

.rx-page-policy-policy-8 {
    display: grid;
    grid-template-columns: 5fr 1fr;
    grid-gap: 8rem;
}

.rx-page-policy-policy-9 {
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-between;
    padding: 12rem 0;

    /* create a gap between flex rows when the content gets wrapped */
    margin: -4rem;
    > * {
        margin: 4rem;
    }
}

.rx-page-policy-policy-10 {
    width: 200rem;
    // account for the margin being set above.
    margin-right: -4rem;
}