



@import 'style';


.rx-root-page-privacy-1 {
    max-width: 650rem;
    background-color: $white;
    color: $gray-90;
    padding: 24rem 0;
    margin: 24rem 0;
    p, h1, h2, h3, h4, ul {
        padding: 0 24rem;
    }
    a {
        @extend %link;
        color: $blue-50;
    }
    h1 {
        @include headline-4;
    }
    h2{
        @include headline-5;
        margin-top: 24rem;
    }
    h3 {
        @include headline-5;
        font-size: 22rem;
        line-height: 150%;
    }
    h4 {
        @include headline-5;
        font-size: 18rem;
        line-height: 150%;
    }
    h5 {
        font-weight: bold;
    }
    p {
        margin: 16rem 0;
        &:first-child {
            margin-top: 0;
        }
        &:last-child {
            margin-bottom: 0;
        }
        @include text-body;
    }
    ul {
        margin: 16rem 0 16rem 16rem;
        @include text-body;
        &:first-child {
            margin-top: 0;
        }
        &:last-child {
            margin-bottom: 0;
        }
        li {
            margin: 16rem 0;
            &:first-child {
                margin-top: 0;
            }
            &:last-child {
                margin-bottom: 0;
            }
            list-style-type: disc;

            p {
                padding: 0;
            }
        }
    }
    h1, h2, h3, h4 {
        & + p {
            margin-top: 0;
        }
    }
    span.italic {
        font-style: italic;
    }
    .divider {
        margin: 24rem 16rem;
        height: 1rem;
        border: 1rem solid $gray-30;
    }
}