















































    @import 'style';


.rx-page-regional-dashboard-index-1 {
    display: grid;
    grid-template-columns: 3fr minmax(100rem, 1fr);
    @include mobile {
        grid-template-columns: 1fr;
    }
    grid-gap: 24rem;
    align-items: start;
}

.rx-page-regional-dashboard-index-2 {
    display: flex;
    flex-direction: column;
    row-gap: 32rem;
    column-gap: 32rem;
}